import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[1];

const Humphrey = ({ data, location }) => (
 <ProfileLayout location={location} profile_name="David M. Humphrey" profile_key="humphrey">
   <Meta site={siteMetadata} title="David M. Humphrey" />
	 	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>One of Canada’s leading criminal defence counsel, David has been a partner in the firm for over thirty years. His advocacy practice is focused on the vigorous defence of individuals and corporations facing criminal investigation or prosecution, and regulated professionals responding to allegations of professional misconduct.</p>

				<p>David has argued cases in all levels of court, including the Supreme Court of Canada. He is equally experienced and adept conducting trials, appeals, and professional discipline proceedings. He has successfully defended many jury trials, and has represented clients at inquests and public inquiries. </p>

				<p>As a reflection of his professional standing, since 2006 David has been consistently named in <i>Best Lawyers in Canada</i> in the practice area of criminal defence. In 2009 he was inducted as a Fellow of the American College of Trial Lawyers.</p>

				<p>David served on the Board of the Criminal Lawyers’ Association from 1993-2009 (as director, secretary and vice-president) and continues to be actively involved. He served as a director of LINK (the Lawyers Assistance Program) and as a director of The Advocates’ Society.</p>

				<p>He has frequently spoken at programs conducted by the Law Society of Upper Canada,  the Canadian Bar Association, the Criminal Lawyers’ Association, the National Judicial Institute, The Advocates’ Society and the Crown Attorney’s Association. He has also been an instructor for the Bar Admission Course and the Ontario Centre for Advocacy Training.</p>

				<p>David received his LL.B. from the University of Western Ontario in 1983. Following his call to the Ontario Bar in 1985, he spent two years as counsel at the Crown Law Office – Criminal. As a member of the private bar, David has occasionally been retained to act as special prosecutor for the Ontario Ministry of the Attorney General.</p>
			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	 
 </ProfileLayout>
)
export default Humphrey
